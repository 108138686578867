import { Backdrop, Badge, Box, Button, Fade, Modal, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search'
import { ReactNode, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { SearchButton } from "../Ui/Button";

export const SearchModal = ({ className, children, onSubmit }: { className?: string, children: ReactNode, onSubmit: any }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const { control, formState: { errors } } = useFormContext()

    // フォームの値を監視
    const formValues = useWatch({ control })
    const [searchCount, setSearchCount] = useState(0)

    useEffect(() => {
        setSearchCount(countValidFields(formValues))
    }, [formValues])

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        bgcolor: 'background.paper',
        borderRadius: '6px',
        boxShadow: 24,
        p: 4,
    };

    return (
        <>
            <Badge color="secondary" overlap="circular" badgeContent={searchCount} className={className}>
                <Button 
                    size="small" 
                    variant="contained"
                    onClick={handleOpen}
                    endIcon={<SearchIcon />}
                >
                    絞込み検索
                </Button>
            </Badge>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <form id="search_modal_form" onSubmit={onSubmit}>
                            {children}

                            <div className="mt-8 text-center">
                                <SearchButton />
                            </div>
                        </form>
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}

// childrenで受け取ったフォーム項目で、入力済みの項目数を返す
const countValidFields = (obj: any) => {
    let count = 0
  
    for (const key in obj) {
        if (Array.isArray(obj[key])) {
            if (obj[key].length > 0) {
                count++
            }
        } else {
            if (obj[key] !== undefined && obj[key] !== null && obj[key] !== '') {
                count++
            }
        }
    }
  
    return count
}