import { useEventSelectOptions, usePropertySelectOptions } from "../../Hooks/User/GoogleAnalytics"
import { Select } from "../Ui/Form"

export const GAEventSelect = ({ site_id, name, placeholder='イベントを選択してください', onChange }: { site_id: string, name: string, placeholder?: string, onChange?: (newValue: number | string) => void }) => {
    const { options, isLoading, isError } = useEventSelectOptions(site_id)

    if (isLoading) return <span className="text-xs">利用可能なイベントを取得中です...</span>
    if (isError) {
        window.location.href = `/auth/google_oauth/callback?site_id=${site_id}`
    }

    return (
        <div className="inline-block w-auto">
            <Select name={name} options={options} placeholder={placeholder} onChange={onChange} />
        </div>
    )
}