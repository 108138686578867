import { NavLink } from "react-router-dom"
import LanguageIcon from '@mui/icons-material/Language'
import DashboardIcon from '@mui/icons-material/Dashboard'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import AssessmentIcon from '@mui/icons-material/Assessment'
import TagIcon from '@mui/icons-material/Tag'

export const SideNavi = () => {
    return (
        <>
            <aside>
                <nav className="sidebar">
                    <h5><span>Google Analytics 4</span></h5>
                    <NavLink
                        to="/analytics/dashboard"
                        className={({ isActive }: { isActive: boolean}) => isActive? 'selected' : ''}
                    >
                        <span className="menu">
                            <span className="icon"><DashboardIcon /></span>
                            <span className="title">dashboard</span>
                        </span>
                    </NavLink>

                    <hr />

                    <NavLink
                        to="/analytics/daily_summary_logs"
                        className={({ isActive }: { isActive: boolean}) => isActive? 'selected' : ''}
                    >
                        <span className="menu">
                            <span className="icon"><AssessmentIcon /></span>
                            <span className="title"><span className="text-xs">[日次]</span>サマリーレポート</span>
                        </span>
                    </NavLink>
                    <NavLink
                        to="/analytics/daily_landing_page_logs"
                        className={({ isActive }: { isActive: boolean}) => isActive? 'selected' : ''}
                    >
                        <span className="menu">
                            <span className="icon"><AssessmentIcon /></span>
                            <span className="title"><span className="text-xs">[日次]</span>LPレポート</span>
                        </span>
                    </NavLink>
                    <NavLink
                        to="/analytics/daily_page_path_logs"
                        className={({ isActive }: { isActive: boolean}) => isActive? 'selected' : ''}
                    >
                        <span className="menu">
                            <span className="icon"><AssessmentIcon /></span>
                            <span className="title"><span className="text-xs">[日次]</span>ページパスレポート</span>
                        </span>
                    </NavLink>
                    <NavLink
                        to="/analytics/daily_channel_group_logs"
                        className={({ isActive }: { isActive: boolean}) => isActive? 'selected' : ''}
                    >
                        <span className="menu">
                            <span className="icon"><AssessmentIcon /></span>
                            <span className="title"><span className="text-xs">[日次]</span>チャネルレポート</span>
                        </span>
                    </NavLink>
                    <NavLink
                        to="/analytics/daily_device_category_logs"
                        className={({ isActive }: { isActive: boolean}) => isActive? 'selected' : ''}
                    >
                        <span className="menu">
                            <span className="icon"><AssessmentIcon /></span>
                            <span className="title"><span className="text-xs">[日次]</span>デバイスレポート</span>
                        </span>
                    </NavLink>
                    <NavLink
                        to="/analytics/daily_page_segment_landing_page_logs"
                        className={({ isActive }: { isActive: boolean}) => isActive? 'selected' : ''}
                    >
                        <span className="menu">
                            <span className="icon"><AssessmentIcon /></span>
                            <span className="title"><span className="text-xs">[日次]</span>ページ分類xLPレポート</span>
                        </span>
                    </NavLink>
                    <NavLink
                        to="/analytics/daily_page_segment_page_path_logs"
                        className={({ isActive }: { isActive: boolean}) => isActive? 'selected' : ''}
                    >
                        <span className="menu">
                            <span className="icon"><AssessmentIcon /></span>
                            <span className="title"><span className="text-xs">[日次]</span>ページ分類xページパスレポート</span>
                        </span>
                    </NavLink>

                    <hr />

                    <NavLink
                        to="/analytics/monthly_summary_logs"
                        className={({ isActive }: { isActive: boolean}) => isActive? 'selected' : ''}
                    >
                        <span className="menu">
                            <span className="icon"><AssessmentIcon /></span>
                            <span className="title"><span className="text-xs">[月次]</span>サマリーレポート</span>
                        </span>
                    </NavLink>
                    <NavLink
                        to="/analytics/monthly_landing_page_logs"
                        className={({ isActive }: { isActive: boolean}) => isActive? 'selected' : ''}
                    >
                        <span className="menu">
                            <span className="icon"><AssessmentIcon /></span>
                            <span className="title"><span className="text-xs">[月次]</span>LPレポート</span>
                        </span>
                    </NavLink>
                    <NavLink
                        to="/analytics/monthly_page_path_logs"
                        className={({ isActive }: { isActive: boolean}) => isActive? 'selected' : ''}
                    >
                        <span className="menu">
                            <span className="icon"><AssessmentIcon /></span>
                            <span className="title"><span className="text-xs">[月次]</span>ページパスレポート</span>
                        </span>
                    </NavLink>
                    <NavLink
                        to="/analytics/monthly_channel_group_logs"
                        className={({ isActive }: { isActive: boolean}) => isActive? 'selected' : ''}
                    >
                        <span className="menu">
                            <span className="icon"><AssessmentIcon /></span>
                            <span className="title"><span className="text-xs">[月次]</span>チャネルレポート</span>
                        </span>
                    </NavLink>
                    <NavLink
                        to="/analytics/monthly_device_category_logs"
                        className={({ isActive }: { isActive: boolean}) => isActive? 'selected' : ''}
                    >
                        <span className="menu">
                            <span className="icon"><AssessmentIcon /></span>
                            <span className="title"><span className="text-xs">[月次]</span>デバイスレポート</span>
                        </span>
                    </NavLink>
                    <NavLink
                        to="/analytics/monthly_page_segment_landing_page_logs"
                        className={({ isActive }: { isActive: boolean}) => isActive? 'selected' : ''}
                    >
                        <span className="menu">
                            <span className="icon"><AssessmentIcon /></span>
                            <span className="title"><span className="text-xs">[月次]</span>ページ分類xLPレポート</span>
                        </span>
                    </NavLink>
                    <NavLink
                        to="/analytics/monthly_page_segment_page_path_logs"
                        className={({ isActive }: { isActive: boolean}) => isActive? 'selected' : ''}
                    >
                        <span className="menu">
                            <span className="icon"><AssessmentIcon /></span>
                            <span className="title"><span className="text-xs">[月次]</span>ページ分類xページパスレポート</span>
                        </span>
                    </NavLink>


                    <h5><span>Setting Menu</span></h5>
                    <NavLink
                        to="/sites"
                        className={({ isActive }: { isActive: boolean}) => isActive? 'selected' : ''}
                    >
                        <span className="menu">
                            <span className="icon"><LanguageIcon /></span>
                            <span className="title">サイト設定</span>
                        </span>
                    </NavLink>
                    <NavLink
                        to="/page_segments"
                        className={({ isActive }: { isActive: boolean}) => isActive? 'selected' : ''}
                    >
                        <span className="menu">
                            <span className="icon"><TagIcon /></span>
                            <span className="title">ページ分類設定</span>
                        </span>
                    </NavLink>

                    <NavLink
                        to="/users"
                        className={({ isActive }: { isActive: boolean}) => isActive? 'selected' : ''}
                    >
                        <span className="menu">
                            <span className="icon"><PeopleAltIcon /></span>
                            <span className="title">共有ユーザー</span>
                        </span>
                    </NavLink>
                </nav>
            </aside>
        </>
    );
}
