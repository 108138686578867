import React from 'react';
import ReactECharts from 'echarts-for-react';
import { useTheme } from '@mui/material';

export const EChartPie = ({ name, data }: { name: string, data: { value: number, name: string }[] }) => {
    // dataのvalueが全て0の場合には空文字を返す
    const total = data.reduce((sum, item) => sum + item.value, 0)
    if (total == 0) return ''

    const theme = useTheme()
    const chartColors = theme.chart.colors;

    const option = {
        color: chartColors,
        tooltip: {
            trigger: 'item',
            position: (point: [number, number], params: any, dom: HTMLElement, rect: { width: number, height: number }) => {
                // `point`はマウスの位置
                // `params`にはアイテムの情報
                // `dom`はtooltipのDOM要素
                // `rect`はtooltipのサイズ
        
                // マウスの位置を基にして、少しオフセットする例
                return [point[0] - 70, point[1] + 30]
            }
        },
        legend: {
            top: '90%',
            left: 'center',
        },
        series: [
            {
                name: name,
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                label: {
                    show: false,
                },
                emphasis: {
                    label: {
                        show: false,
                    }
                },
                labelLine: {
                    show: false
                },
                data: data,
            }
        ]
    };

    return <ReactECharts option={option} style={{ height: '100%', width: '100%' }} />;
}
