import { FC, useEffect, useState } from "react"
import { ColumnHelper, createColumnHelper } from '@tanstack/react-table'
import AssessmentIcon from '@mui/icons-material/Assessment'
import { useSite } from "../../../../Providers/Site"
import { FormProvider, useForm } from "react-hook-form"
import { Message } from "../../../Ui/Message"
import { Loading } from "../../../Ui/Loading"
import { H1 } from "../../../Ui/H1"
import { PaginationTable } from "../../../Ui/Table"
import { roundWithScale } from "../../../../Utils/Math"
import { secToTime } from "../../../../Utils/Time"
import { GAChannelGroupLog, SearchGAChannelGroupLog } from "../../../../Types/GAChannelGroupLog"
import { Tooltip } from "@mui/material"
import { truncateString } from "../../../../Utils/Str"
import { useGAChannelGroupLogList } from "../../../../Hooks/User/GAChannelGroupLog"
import { SearchSummaryLog, SummaryLog } from "../../../../Types/SummaryLog"
import { useSummaryLogList } from "../../../../Hooks/User/SummaryLog"
import { CSVDownloadButton } from "../../../Ui/Button"

export const DailySummaryLog: FC = () => {
    const [message, setMessage] = useState('')

    const [searchParams, setSearchParams] = useState<SearchSummaryLog>()
    const { siteId } = useSite()

    const useFormMethods = useForm<SearchSummaryLog>()
    const { handleSubmit } = useFormMethods;

    // データ取得
    const { isPending, data } = useSummaryLogList(searchParams)
    useEffect(() => {
        if (data && data.length >= 20000) {
            setMessage('データが多すぎたため一部のデータのみ表示しています。絞り込み検索を行ってください。')
        }
    }, [data])

    const columnHelper = createColumnHelper<SummaryLog>()
    const columns = setColumns(columnHelper)

    useEffect(() => {
        if (siteId) {
            setSearchParams({site_id: Number(siteId)})
        }
    }, [siteId])

    if (!siteId) {
        return <Message message="ヘッダーのプルダウンより対象のサイトを選択してください" />
    } else if (isPending) {
        return <Loading isLoading={isPending} />
    }
    return (
        <>        
            <Message message={message} />
            <H1 icon={<AssessmentIcon />}>[日次]サマリーレポート</H1>

            <FormProvider {...useFormMethods}>
                <PaginationTable data={data} columns={columns} isSearchParts={true}>
                    <CSVDownloadButton columns={csvColumns} data={data} filename="[日次]サマリーレポート" className="ml-2" />
                </PaginationTable>
            </FormProvider>
        </>
    )
}

const setColumns = (columnHelper: ColumnHelper<SummaryLog>) => {
    return [
        columnHelper.accessor('date', {
            header: '日付',
            cell: (props) => (
                <div className="text-nowrap">{props.row.original.date}</div>
            )
        }),
        columnHelper.accessor('sessions', {
            header: 'セッション数',
            cell: (props) => (
                <div className="text-right">{props.row.original.sessions.toLocaleString()}</div>
            )
        }),
        columnHelper.accessor('screen_page_views', {
            // header: 'ページビュー数'
            header: 'PV',
            cell: (props) => (
                <div className="text-right">{props.row.original.screen_page_views.toLocaleString()}</div>
            )
        }),
        columnHelper.accessor('screen_page_views_per_session', {
            // header: 'セッションあたりのページビュー数',
            header: 'PV/セッション',
            cell: (props) => (
                <div className="text-right">{roundWithScale(props.row.original.screen_page_views_per_session, 2).toFixed(2)}</div>
            )
        }),
        columnHelper.accessor('new_users', {
            header: '新規ユーザー数',
            cell: (props) => (
                <div className="text-right">{props.row.original.new_users.toLocaleString()}</div>
            )
        }),
        columnHelper.accessor('new_user_rate', {
            header: '新規ユーザー率',
            cell: (props) => (
                <div className="text-right">{roundWithScale(props.row.original.new_user_rate, 2).toFixed(2) + '%'}</div>
            )
        }),
        columnHelper.accessor('average_session_duration', {
            header: '平均滞在時間',
            cell: (props) => (
                <div className="text-right">{secToTime(props.row.original.average_session_duration)}</div>
            )
        }),
        columnHelper.accessor('bounce_rate', {
            header: '離脱率',
            cell: (props) => (
                <div className="text-right">{roundWithScale(props.row.original.bounce_rate, 2).toFixed(2) + '%'}</div>
            )
        }),
        columnHelper.accessor('conversions', {
            // header: 'コンバージョン数'
            header: 'CV',
            cell: (props) => (
                <div className="text-right">{props.row.original.conversions.toLocaleString()}</div>
            )
        }),
        columnHelper.accessor('conversion_rate', {
            // header: 'コンバージョン率',
            header: 'CVR',
            cell: (props) => (
                <div className="text-right">{roundWithScale(props.row.original.conversion_rate, 2).toFixed(2) + '%'}</div>
            )
        }),
    ]
}

const csvColumns = [
    { header: '日付', key: 'date' },
    { header: 'セッション数', key: 'sessions' },
    { header: 'PV', key: 'screen_page_views' },
    { header: 'PV/セッション', key: 'screen_page_views_per_session', fn: (value: string) => roundWithScale(Number(value), 2).toFixed(2) },
    { header: '新規ユーザー数', key: 'new_users' },
    { header: '新規ユーザー率', key: 'average_session_duration', fn: (value: string) => roundWithScale(Number(value), 2).toFixed(2) + '%' },
    { header: '平均滞在時間', key: 'new_users', fn: (value: string) => secToTime(Number(value)) },
    { header: '離脱率', key: 'bounce_rate', fn: (value: string) => roundWithScale(Number(value), 2).toFixed(2) + '%' },
    { header: 'CV', key: 'conversions' },
    { header: 'CVR', key: 'conversion_rate', fn: (value: string) => roundWithScale(Number(value), 2).toFixed(2) + '%' },
]