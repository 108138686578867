import axios, { AxiosResponse } from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { PageSegment, SearchPageSegment } from '../../Types/PageSegment'

export const getPageSegmentList = async (searchParams: SearchPageSegment | undefined) => {
    const { data } = await axios.get('/api/page_segments', { params: searchParams })
    return data
}

export const getPageSegment = async (id: string) => {
    const { data } = await axios.get(`/api/page_segments/${id}`)
    return data
}

export const updatePageSegment = async (data: PageSegment): Promise<AxiosResponse> => {
    return await axios.patch('/api/page_segments', data)
}

export const createPageSegment = async (data: PageSegment): Promise<AxiosResponse> => {
    return await axios.post('/api/page_segments', data)
}

// use react-query
export const usePageSegmentList = (searchParams: SearchPageSegment | undefined): UseQueryResult<PageSegment[]> => {
    return useQuery({
        queryKey: ['getPageSegmentList', searchParams],
        queryFn: () => getPageSegmentList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}

// export const useUserSelectOptions = () => {
//     const { data: userList, ...queryInfo } = useUserList()

//     const selectOptions = useMemo(() => {
//         if (!userList) return []

//         return userList.map((user: User) => (
//             {
//                 value: user.id,
//                 label: `${user.name}（${user.email}）`,
//             }
//         ))
//     }, [userList])

//     return { selectOptions, ...queryInfo }
// }